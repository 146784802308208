module.exports = [{
      plugin: require('/mnt/c/Users/Matt/Desktop/personal-site/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#4285f4","showSpinner":false},
    },{
      plugin: require('/mnt/c/Users/Matt/Desktop/personal-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145041835-1","head":true},
    },{
      plugin: require('/mnt/c/Users/Matt/Desktop/personal-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/mnt/c/Users/Matt/Desktop/personal-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
